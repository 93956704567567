/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Lock { 
    SerialNumber?: string;
    LockType?: string;
    Subtype?: string;
    NotBilled?: boolean;
    HWAddress?: string;
    Available?: boolean;
    AddedBy?: string;
    AddedOnUTC?: Date;
    UnlockAlgorithm?: string;
    FWVersion?: string;
    ManualUnlockCode?: string;
}
