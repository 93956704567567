/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LockLocation } from './lockLocation';
import { UserDetailsLimited } from './userDetailsLimited';


export interface LockDetailsWithOwnerModel { 
    Owner?: UserDetailsLimited;
    Id?: string;
    LockType?: string;
    UID?: string;
    OptionalDetails?: string;
    ClientId?: string;
    Subtype?: string;
    DisplayName?: string;
    Location?: LockLocation;
    LastUnlockedBy?: UserDetailsLimited;
    LastUnlockedAtUtc?: Date;
    LastBatteryLevel?: string;
    NotBilled?: boolean;
    HWAddress?: string;
    OfflineEnabled?: boolean;
    UnlockAlgorithm?: string;
    LockStatus?: string;
    LockStatusUpdatedOnUtc?: Date;
}
