/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CodeDetailsModel } from '../model/codeDetailsModel';
import { NewCodeModel } from '../model/newCodeModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class CodesService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets all active authorization codes owned by the current user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public codesActive(observe?: 'body', reportProgress?: boolean): Observable<Array<CodeDetailsModel>>;
    public codesActive(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CodeDetailsModel>>>;
    public codesActive(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CodeDetailsModel>>>;
    public codesActive(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CodeDetailsModel>>(`${this.basePath}/api/Codes/Active`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a new authorization code to the system.
     * 
     * @param Code Object containing auth code details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public codesAdd(Code: NewCodeModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public codesAdd(Code: NewCodeModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public codesAdd(Code: NewCodeModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public codesAdd(Code: NewCodeModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Code === null || Code === undefined) {
            throw new Error('Required parameter Code was null or undefined when calling codesAdd.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/Codes/Add`,
            Code,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a deep link with the given unique code embedded into it
     * 
     * @param Id A valid authorization code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public codesDeepLink(Id: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public codesDeepLink(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public codesDeepLink(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public codesDeepLink(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling codesDeepLink.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/Codes/DeepLink/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the given authorization code  Note that only the owner of the resource can delete it.
     * 
     * @param Id A valid authorization code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public codesDelete(Id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public codesDelete(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public codesDelete(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public codesDelete(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling codesDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/Codes/Delete/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all expires authorization codes owned by the current user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public codesPast(observe?: 'body', reportProgress?: boolean): Observable<Array<CodeDetailsModel>>;
    public codesPast(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CodeDetailsModel>>>;
    public codesPast(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CodeDetailsModel>>>;
    public codesPast(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CodeDetailsModel>>(`${this.basePath}/api/Codes/Past`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
