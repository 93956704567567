<div class="pl-1">
  <input
    #autoInput
    nbInput
    type="text"
    [placeholder]="placeholder"
    [nbAutocomplete]="auto"
    (input)="onChange()" />
  <nb-autocomplete #auto (selectedChange)="selectedChange($event)">
    <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.Id">
      <p>
        {{ option.DisplayName }} <span *ngIf="option.UID" class="small"> ({{ option.UID }})</span>
      </p>
    </nb-option>
  </nb-autocomplete>
</div>
