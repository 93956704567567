import { Injectable } from '@angular/core';
import { LockActivityModel, LockDetailsWithOwnerModel } from '../lockvue-ng-sdk';
import { LockDetailsUIModel } from '../models/LockDetailsWithPermissionModel';
import { DateFilterService } from './date-filter.service';
import { LockvueConfigProviderService } from './lockvue-config-provider.service';

@Injectable({
  providedIn: 'root',
})
export class TableSettingsUtilService {
  constructor(
    private configProvider: LockvueConfigProviderService,
    private dateFilterService: DateFilterService,
  ) {}

  getNumLocks(
    lockType: string,
    _locks: LockDetailsUIModel[] | LockDetailsWithOwnerModel[],
  ): number {
    let count = 0;
    if (_locks && _locks.length > 0) {
      _locks.forEach(l => (l.LockType === lockType ? ++count : false));
    }
    return count;
  }

  getLockManufacturerList(_locks: LockDetailsUIModel[] | LockDetailsWithOwnerModel[]) {
    const lockManufacturersList = [];

    const _lockTypes = this.configProvider.getLockTypes();

    _lockTypes.forEach(lockType =>
      lockManufacturersList.push({
        value: lockType.manufacturer,
        title: lockType.manufacturer + ' (' + this.getNumLocks(lockType.manufacturer, _locks) + ')',
      }),
    );
    return lockManufacturersList;
  }

  getLockSubtypes(_locks: LockDetailsUIModel[] | LockDetailsWithOwnerModel[]) {
    const lockSubtypes = {};
    if (_locks && _locks.length > 0) {
      _locks.forEach(l => {
        if (!lockSubtypes[l.Subtype]) {
          lockSubtypes[l.Subtype] = 0;
        }
        lockSubtypes[l.Subtype]++;
      });
    }

    const lockSubtypesFilter = [];
    for (const property in lockSubtypes) {
      if (lockSubtypes.hasOwnProperty(property)) {
        lockSubtypesFilter.push({
          value: property,
          title: property + ' (' + lockSubtypes[property] + ')',
        });
      }
    }
    return lockSubtypesFilter;
  }

  getExpiresOnFilterList() {
    const _expiresOnFilterItems = [];
    const filterItems = this.dateFilterService.getFilters('Unknown');
    filterItems.forEach(item => {
      _expiresOnFilterItems.push({ value: item, title: item });
    });
    return _expiresOnFilterItems;
  }

  getActionFilters() {
    return [
      { value: 'All_Unlocks', title: 'All Unlock Events' },
      { value: 'All_Permissions', title: 'All Permission Events' },
      { value: 'All_KeyRack', title: 'All KeyRack Events' },
      { value: LockActivityModel.EventActionEnum.Request, title: 'Request' },
      { value: LockActivityModel.EventActionEnum.Unlocked, title: 'Unlocked' },
      {
        value: LockActivityModel.EventActionEnum.ShackleRemoved,
        title: 'Shackle Removed',
      },
      { value: LockActivityModel.EventActionEnum.Locked, title: 'Locked' },
      { value: LockActivityModel.EventActionEnum.Battery, title: 'Battery' },
      {
        value: LockActivityModel.EventActionEnum.Added,
        title: 'Lock Activation',
      },
      { value: LockActivityModel.EventActionEnum.Created, title: 'Created' },
      { value: LockActivityModel.EventActionEnum.Deleted, title: 'Deleted' },

      {
        value: LockActivityModel.EventActionEnum.LockStatus,
        title: 'Lock Status',
      },
      {
        value: LockActivityModel.EventActionEnum.Removed,
        title: 'Lock Deactivation',
      },
      {
        value: LockActivityModel.EventActionEnum.Granted,
        title: 'Permission Granted',
      },
      {
        value: LockActivityModel.EventActionEnum.Revoked,
        title: 'Permission Revoked',
      },
      {
        value: LockActivityModel.EventActionEnum.Modified,
        title: 'Lock Details Modified',
      },
      {
        value: LockActivityModel.EventActionEnum.Heartbeat,
        title: 'Heartbeat',
      },
      {
        value: LockActivityModel.EventActionEnum.PegRemoved,
        title: 'Peg Removed',
      },
      {
        value: LockActivityModel.EventActionEnum.PegReturned,
        title: 'Peg Returned',
      },
      {
        value: LockActivityModel.EventActionEnum.PegUnlocked,
        title: 'Peg Unlocked',
      },
      {
        value: LockActivityModel.EventActionEnum.DoorUnlocked,
        title: 'Door Unlocked',
      },
      {
        value: LockActivityModel.EventActionEnum.UnlockAll,
        title: 'Unlock All',
      },
    ];
  }

  activityActionFilterFunction(cell, search) {
    if (!search) return true;
    if (search === 'All_Unlocks') {
      return (
        LockActivityModel.EventActionEnum.Unlocked === cell ||
        LockActivityModel.EventActionEnum.ShackleRemoved === cell ||
        LockActivityModel.EventActionEnum.Locked === cell ||
        LockActivityModel.EventActionEnum.Battery === cell
      );
    }
    if (search === 'All_Permissions') {
      return (
        LockActivityModel.EventActionEnum.Granted === cell ||
        LockActivityModel.EventActionEnum.Revoked === cell
      );
    }
    if (search === 'All_KeyRack') {
      return (
        LockActivityModel.EventActionEnum.Heartbeat === cell ||
        LockActivityModel.EventActionEnum.PegRemoved === cell ||
        LockActivityModel.EventActionEnum.PegReturned === cell ||
        LockActivityModel.EventActionEnum.PegUnlocked === cell ||
        LockActivityModel.EventActionEnum.UnlockAll === cell ||
        LockActivityModel.EventActionEnum.DoorUnlocked === cell
      );
    }
    return search === cell;
  }

  hubAvailableLocksFilterFunction(cell, search): boolean {
    return cell.length.toString().includes(search);
  }

  groupNameFilterFunction(cell, search): boolean {
    return cell.Name?.toLowerCase().includes(search);
  }
}
