<div class="w-100 d-flex flex-nowrap justify-content-around">
  <div (click)="navigate('/locks')">
    <nb-card class="system-stat-item">
      <div class="icon-container">
        <nb-icon icon="ion-unlocked"></nb-icon>
      </div>
      <div class="details pl-2 pr-2">
        <div class="title h4 text-center" *ngIf="systemSummary && systemSummary.Locks">
          <!-- {{systemSummary.Locks.OwnedLocks + systemSummary.Locks.AdminLocks +
                    systemSummary.Locks.ViewLocks + systemSummary.Locks.OneTimeLocks}} -->
          {{ lockCount }}
        </div>
        <div class="status paragraph-2">Locks</div>
      </div>
    </nb-card>
  </div>
  <div (click)="navigate('/lock-groups')">
    <nb-card class="system-stat-item">
      <div class="icon-container">
        <nb-icon icon="ion-ios-keypad"></nb-icon>
      </div>
      <div class="details pl-2 pr-2">
        <div class="title h4 text-center" *ngIf="systemSummary">
          {{ systemSummary.Groups }}
        </div>
        <div class="status paragraph-2">Lock Groups</div>
      </div>
    </nb-card>
  </div>
  <div (click)="navigate('/teams')">
    <nb-card class="system-stat-item">
      <div class="icon-container">
        <nb-icon icon="ion-ios-people"></nb-icon>
      </div>
      <div class="details pl-2 pr-2">
        <div class="title h4 text-center" *ngIf="systemSummary">
          {{ systemSummary.Teams }}
        </div>
        <div class="status paragraph-2">Teams</div>
      </div>
    </nb-card>
  </div>
  <div (click)="navigate('/activity')">
    <nb-card class="system-stat-item" *ngIf="telemetrySummary">
      <div class="icon-container">
        <nb-icon icon="ion-ios-pulse-strong"></nb-icon>
      </div>
      <div class="details pl-2 pr-2">
        <div class="title h4 text-center">
          {{ telemetrySummary.UnlocksLast1Hr }}
        </div>
        <div class="status paragraph-2">
          1 <br />
          Hour
        </div>
      </div>
      <div class="details pl-2 pr-2">
        <div class="title h4 text-center">
          {{ telemetrySummary.UnlocksLast24Hr }}
        </div>
        <div class="status paragraph-2">
          24 <br />
          Hours
        </div>
      </div>
      <div class="details pl-2 pr-2">
        <div class="title h4 text-center">
          {{ telemetrySummary.TotalUnlocks }}
        </div>
        <div class="status paragraph-2">
          All <br />
          Time
        </div>
      </div>
    </nb-card>
  </div>
</div>
