/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UnlockRequestModel { 
    LockId?: string;
    Session?: string;
    AppId?: string;
    UnlockType?: UnlockRequestModel.UnlockTypeEnum;
}
export namespace UnlockRequestModel {
    export type UnlockTypeEnum = 'Unlock' | 'ShackleRemove' | 'UnlockPeg' | 'UnlockDoor' | 'UnlockDoorAndPeg';
    export const UnlockTypeEnum = {
        Unlock: 'Unlock' as UnlockTypeEnum,
        ShackleRemove: 'ShackleRemove' as UnlockTypeEnum,
        UnlockPeg: 'UnlockPeg' as UnlockTypeEnum,
        UnlockDoor: 'UnlockDoor' as UnlockTypeEnum,
        UnlockDoorAndPeg: 'UnlockDoorAndPeg' as UnlockTypeEnum
    };
}
