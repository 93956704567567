import { Component, OnInit, Input } from '@angular/core';
import { LockActivityUIModel } from '../../models/LockActivityUIModel';
import { LockActivityModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-activity-action',
  templateUrl: './activity-action.component.html',
  styleUrls: ['./activity-action.component.scss'],
})
export class ActivityActionComponent implements OnInit {
  @Input() value: LockActivityModel.EventActionEnum;
  @Input() rowData: LockActivityUIModel;
  requestValue = '';

  constructor() {}

  ngOnInit() {
    if (this.rowData.EventAction === 'Request') {
      this.requestValue = this.rowData.Value.split(/(?=[A-Z])/).join(' ');
    }
  }
}
