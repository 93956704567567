/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EventLogDetailsItem } from '../model/eventLogDetailsItem';
import { LockActivityModel } from '../model/lockActivityModel';
import { TelemetryFetchModel } from '../model/telemetryFetchModel';
import { TelemetrySummary } from '../model/telemetrySummary';
import { TelemetrySummaryAll } from '../model/telemetrySummaryAll';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns all activities performed on the resources owned or administered by the current user
     * 
     * @param StartTimeUTC Start time in UTC
     * @param EndTimeUTC End time in UTC
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telemetryAll(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<EventLogDetailsItem>>;
    public telemetryAll(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EventLogDetailsItem>>>;
    public telemetryAll(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EventLogDetailsItem>>>;
    public telemetryAll(StartTimeUTC: Date, EndTimeUTC: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (StartTimeUTC === null || StartTimeUTC === undefined) {
            throw new Error('Required parameter StartTimeUTC was null or undefined when calling telemetryAll.');
        }

        if (EndTimeUTC === null || EndTimeUTC === undefined) {
            throw new Error('Required parameter EndTimeUTC was null or undefined when calling telemetryAll.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (StartTimeUTC !== undefined && StartTimeUTC !== null) {
            queryParameters = queryParameters.set('startTimeUTC', <any>StartTimeUTC.toISOString());
        }
        if (EndTimeUTC !== undefined && EndTimeUTC !== null) {
            queryParameters = queryParameters.set('endTimeUTC', <any>EndTimeUTC.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EventLogDetailsItem>>(`${this.basePath}/api/Telemetry/All`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all activities performed on the resources owned or administered by the current user
     * 
     * @param TelemetryFetchModel Object containing filter params to fetch telemetry
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telemetryAllV2(TelemetryFetchModel: TelemetryFetchModel, observe?: 'body', reportProgress?: boolean): Observable<Array<EventLogDetailsItem>>;
    public telemetryAllV2(TelemetryFetchModel: TelemetryFetchModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EventLogDetailsItem>>>;
    public telemetryAllV2(TelemetryFetchModel: TelemetryFetchModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EventLogDetailsItem>>>;
    public telemetryAllV2(TelemetryFetchModel: TelemetryFetchModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (TelemetryFetchModel === null || TelemetryFetchModel === undefined) {
            throw new Error('Required parameter TelemetryFetchModel was null or undefined when calling telemetryAllV2.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<EventLogDetailsItem>>(`${this.basePath}/api/Telemetry/AllV2`,
            TelemetryFetchModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all activities performed on a given lock identified by its unique lock-id.  The lock must be owned or administered by the current user. An HTTP Unauthorized is returned otherwise.
     * 
     * @param StartTimeUTC Start time in UTC
     * @param EndTimeUTC End time in UTC
     * @param LockId A valid unique Id of a lock owned or administered by the current user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telemetryLock(StartTimeUTC: Date, EndTimeUTC: Date, LockId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EventLogDetailsItem>>;
    public telemetryLock(StartTimeUTC: Date, EndTimeUTC: Date, LockId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EventLogDetailsItem>>>;
    public telemetryLock(StartTimeUTC: Date, EndTimeUTC: Date, LockId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EventLogDetailsItem>>>;
    public telemetryLock(StartTimeUTC: Date, EndTimeUTC: Date, LockId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (StartTimeUTC === null || StartTimeUTC === undefined) {
            throw new Error('Required parameter StartTimeUTC was null or undefined when calling telemetryLock.');
        }

        if (EndTimeUTC === null || EndTimeUTC === undefined) {
            throw new Error('Required parameter EndTimeUTC was null or undefined when calling telemetryLock.');
        }

        if (LockId === null || LockId === undefined) {
            throw new Error('Required parameter LockId was null or undefined when calling telemetryLock.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (StartTimeUTC !== undefined && StartTimeUTC !== null) {
            queryParameters = queryParameters.set('startTimeUTC', <any>StartTimeUTC.toISOString());
        }
        if (EndTimeUTC !== undefined && EndTimeUTC !== null) {
            queryParameters = queryParameters.set('endTimeUTC', <any>EndTimeUTC.toISOString());
        }
        if (LockId !== undefined && LockId !== null) {
            queryParameters = queryParameters.set('lockId', <any>LockId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EventLogDetailsItem>>(`${this.basePath}/api/Telemetry/Lock`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all activities performed on the resources owned or administered by the current user
     * 
     * @param StartTimeUTC Start time in UTC
     * @param EndTimeUTC End time in UTC
     * @param EventAction EventAction type, if the results need to be filtered by an event action
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telemetryLocks(StartTimeUTC: Date, EndTimeUTC: Date, EventAction?: 'Locked' | 'Unlocked' | 'Created' | 'Modified' | 'Deleted' | 'Granted' | 'Revoked' | 'Battery' | 'Location' | 'Added' | 'Removed' | 'Ownership_Transferred' | 'Billing_Status_Changed' | 'Shackle_Removed' | 'Heartbeat' | 'Peg_Unlocked' | 'Peg_Removed' | 'Peg_Returned' | 'Door_Unlocked' | 'Unlock_All' | 'Request' | 'Lock_Status' | 'OfflineLocked' | 'OfflineUnlocked', observe?: 'body', reportProgress?: boolean): Observable<Array<LockActivityModel>>;
    public telemetryLocks(StartTimeUTC: Date, EndTimeUTC: Date, EventAction?: 'Locked' | 'Unlocked' | 'Created' | 'Modified' | 'Deleted' | 'Granted' | 'Revoked' | 'Battery' | 'Location' | 'Added' | 'Removed' | 'Ownership_Transferred' | 'Billing_Status_Changed' | 'Shackle_Removed' | 'Heartbeat' | 'Peg_Unlocked' | 'Peg_Removed' | 'Peg_Returned' | 'Door_Unlocked' | 'Unlock_All' | 'Request' | 'Lock_Status' | 'OfflineLocked' | 'OfflineUnlocked', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockActivityModel>>>;
    public telemetryLocks(StartTimeUTC: Date, EndTimeUTC: Date, EventAction?: 'Locked' | 'Unlocked' | 'Created' | 'Modified' | 'Deleted' | 'Granted' | 'Revoked' | 'Battery' | 'Location' | 'Added' | 'Removed' | 'Ownership_Transferred' | 'Billing_Status_Changed' | 'Shackle_Removed' | 'Heartbeat' | 'Peg_Unlocked' | 'Peg_Removed' | 'Peg_Returned' | 'Door_Unlocked' | 'Unlock_All' | 'Request' | 'Lock_Status' | 'OfflineLocked' | 'OfflineUnlocked', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockActivityModel>>>;
    public telemetryLocks(StartTimeUTC: Date, EndTimeUTC: Date, EventAction?: 'Locked' | 'Unlocked' | 'Created' | 'Modified' | 'Deleted' | 'Granted' | 'Revoked' | 'Battery' | 'Location' | 'Added' | 'Removed' | 'Ownership_Transferred' | 'Billing_Status_Changed' | 'Shackle_Removed' | 'Heartbeat' | 'Peg_Unlocked' | 'Peg_Removed' | 'Peg_Returned' | 'Door_Unlocked' | 'Unlock_All' | 'Request' | 'Lock_Status' | 'OfflineLocked' | 'OfflineUnlocked', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (StartTimeUTC === null || StartTimeUTC === undefined) {
            throw new Error('Required parameter StartTimeUTC was null or undefined when calling telemetryLocks.');
        }

        if (EndTimeUTC === null || EndTimeUTC === undefined) {
            throw new Error('Required parameter EndTimeUTC was null or undefined when calling telemetryLocks.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (StartTimeUTC !== undefined && StartTimeUTC !== null) {
            queryParameters = queryParameters.set('startTimeUTC', <any>StartTimeUTC.toISOString());
        }
        if (EndTimeUTC !== undefined && EndTimeUTC !== null) {
            queryParameters = queryParameters.set('endTimeUTC', <any>EndTimeUTC.toISOString());
        }
        if (EventAction !== undefined && EventAction !== null) {
            queryParameters = queryParameters.set('eventAction', <any>EventAction);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LockActivityModel>>(`${this.basePath}/api/Telemetry/Locks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all activities performed by the current user
     * 
     * @param StartTimeUTC Start time in UTC
     * @param EndTimeUTC End time in UTC
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telemetryMy(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<EventLogDetailsItem>>;
    public telemetryMy(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EventLogDetailsItem>>>;
    public telemetryMy(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EventLogDetailsItem>>>;
    public telemetryMy(StartTimeUTC: Date, EndTimeUTC: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (StartTimeUTC === null || StartTimeUTC === undefined) {
            throw new Error('Required parameter StartTimeUTC was null or undefined when calling telemetryMy.');
        }

        if (EndTimeUTC === null || EndTimeUTC === undefined) {
            throw new Error('Required parameter EndTimeUTC was null or undefined when calling telemetryMy.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (StartTimeUTC !== undefined && StartTimeUTC !== null) {
            queryParameters = queryParameters.set('startTimeUTC', <any>StartTimeUTC.toISOString());
        }
        if (EndTimeUTC !== undefined && EndTimeUTC !== null) {
            queryParameters = queryParameters.set('endTimeUTC', <any>EndTimeUTC.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EventLogDetailsItem>>(`${this.basePath}/api/Telemetry/My`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gives useful summary of events logged into the system
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telemetrySummary(observe?: 'body', reportProgress?: boolean): Observable<TelemetrySummary>;
    public telemetrySummary(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TelemetrySummary>>;
    public telemetrySummary(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TelemetrySummary>>;
    public telemetrySummary(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TelemetrySummary>(`${this.basePath}/api/Telemetry/Summary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gives useful summary of events logged into the system
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telemetrySummaryAll(observe?: 'body', reportProgress?: boolean): Observable<TelemetrySummaryAll>;
    public telemetrySummaryAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TelemetrySummaryAll>>;
    public telemetrySummaryAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TelemetrySummaryAll>>;
    public telemetrySummaryAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TelemetrySummaryAll>(`${this.basePath}/api/Telemetry/SummaryAll`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
