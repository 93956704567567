<div class="d-md-flex">
  <div class="activity-date-picker">
    <!-- <label>Pick a date range:</label>
    <br /> -->
    <input nbInput placeholder="Pick Date Range" [nbDatepicker]="formpicker" class="form-control" />
    <nb-rangepicker
      #formpicker
      [range]="range"
      (rangeChange)="rangeChanged($event)"></nb-rangepicker>
    <!-- <span class="caption">Maximum {{ differenceAllowed }} months difference allowed.</span> -->
  </div>
  <div>
    <nb-select [selected]="1" (selectedChange)="dateRangeChanged($event)">
      <nb-option [value]="1">1D</nb-option>
      <nb-option [value]="7">7D</nb-option>
      <nb-option [value]="30">30D</nb-option>
      <nb-option [value]="90">90D</nb-option>
    </nb-select>
  </div>
  <!-- <div class="time-range-options pl-3 pt-1 pt-md-4">
    <button nbButton outline (click)="updateRange_today()">Day</button>
  </div>
  <div class="time-range-options pl-3 pt-1 pt-md-4">
    <button nbButton outline (click)="updateRange_week()">Week</button>
  </div>
  <div class="time-range-options pl-3 pt-1 pt-md-4">
    <button nbButton outline (click)="updateRange_month()">Month</button>
  </div>
  <div *ngIf="isAdmin">
    <div class="time-range-options pl-3 pt-1 pt-md-4">
      <button nbButton outline (click)="updateRange_3months()">3 Months</button>
    </div>
  </div>
  <div *ngIf="!isAdmin" class="d-flex">
    <div class="time-range-options pl-3 pt-1 pt-md-4 d-none d-md-block">
      <button nbButton outline (click)="updateRange_6Months()">6 Months</button>
    </div>
    <div class="time-range-options pl-3 pt-1 pt-md-4 d-none d-lg-block">
      <button nbButton outline (click)="updateRange_12Months()">12 Months</button>
    </div>
  </div> -->
</div>
