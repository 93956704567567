import { Injectable } from '@angular/core';
import { LockActivityModel, LockInHubDetailsModel } from '../lockvue-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class LockIconHelperService {
  constructor() {}

  getIconColor(rowData: any) {
    const greenIconEvents = [
      LockActivityModel.EventActionEnum.Unlocked,
      LockActivityModel.EventActionEnum.PegUnlocked,
      LockActivityModel.EventActionEnum.ShackleRemoved,
      LockActivityModel.EventActionEnum.DoorUnlocked,
    ];
    // rowData can be empty if the component is instantiated outside of a smart table.
    return greenIconEvents.includes(rowData.EventAction)
      ? rowData.Value
        ? 'marker'
        : 'green'
      : 'black';
  }

  getKRIcon(isLock: LockInHubDetailsModel, column: number) {
    if (isLock && column === 1) {
      return 'First-K';
    } else if (isLock && column === 14) {
      return 'Last-K';
    } else if (!isLock && column === 1) {
      return 'First-NK';
    } else if (!isLock && column === 14) {
      return 'Last-NK';
    } else if (isLock) {
      return 'Mid-K';
    } else {
      return 'Mid-NK';
    }
  }
}
