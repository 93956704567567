import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { LockDetailsUIModel } from '../../models/LockDetailsWithPermissionModel';
import {
  LockDetailsModel,
  LockDetailsWithPermissionModel,
  LocksService,
} from '../../lockvue-ng-sdk';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NbTagComponent, NbTagInputDirective } from '@nebular/theme';

@Component({
  selector: 'lockvue-lock-selector',
  templateUrl: './lock-selector.component.html',
  styleUrls: ['./lock-selector.component.scss'],
})
export class LockSelectorComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  ownLocks: LockDetailsModel[];
  adminLocks: LockDetailsModel[];
  myLocks: LockDetailsUIModel[]; // own and admin locks combined
  availableLocks: LockDetailsUIModel[];
  filteredLocks$: Observable<LockDetailsUIModel[]>;
  value: string;
  selectedLocksSet: Set<{ Name: string; Id: string; Removable: boolean }>;

  @Input() text: string = 'Type Name or Serial to search for locks';
  @Input() allowMulti: boolean;

  @Input() selectedLocks: LockDetailsUIModel[] = [];

  @Output() selectedLocksChange: EventEmitter<
    Set<{ Name: string; Id: string; Removable: boolean }>
  > = new EventEmitter();

  @ViewChild(NbTagInputDirective, { read: ElementRef }) tagInput: ElementRef<HTMLInputElement>;

  constructor(private locksService: LocksService) {}

  ngOnInit() {
    this.loadMyLocks();
    this.selectedLocksSet = new Set(
      this.selectedLocks.map(v => ({ Name: v.DisplayName, Id: v.Id, Removable: false })),
    );
  }

  private loadMyLocks() {
    this.locksService
      .locksOwn()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.onOwnLocksResult(data));
    this.locksService
      .locksSharedWithMe()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.onSharedLocksResult(data));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private onOwnLocksResult(data: LockDetailsModel[]) {
    this.ownLocks = data;
    this.refreshMyLocks();
  }

  // Handles shared locks result stores admin locks
  private onSharedLocksResult(data: LockDetailsWithPermissionModel[]) {
    if (data && data.length > 0) {
      data.forEach(item => {
        if (item.PermissionType === 'Admin') {
          this.adminLocks = item.Locks;
        }
      });
    }
    this.refreshMyLocks();
  }

  private refreshMyLocks() {
    // Clear the array
    this.myLocks = [];

    // Add own locks
    if (this.ownLocks && this.ownLocks.length > 0) {
      this.ownLocks.forEach(item => {
        const model = item as LockDetailsUIModel;
        model.Permission = 'Owner';
        model.EditPermission = true;
        model.SharePermission = true;
        model.DeactivatePermission = true;

        this.myLocks.push(model);
      });
    }

    // Add admin locks
    if (this.adminLocks && this.adminLocks.length > 0) {
      this.adminLocks.forEach(item => {
        const model = item as LockDetailsUIModel;
        model.Permission = 'Admin';
        model.EditPermission = true;
        model.SharePermission = true;
        model.DeactivatePermission = true;

        this.myLocks.push(model);
      });
    }
    this.availableLocks = this.myLocks;
    this.availableLocks = this.availableLocks.filter(
      v => this.selectedLocks.findIndex(s => s.Id === v.Id) < 0,
    );
    this.filteredLocks$ = of(this.availableLocks);
  }

  private filter(value: string): LockDetailsUIModel[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.availableLocks.filter(
        optionValue =>
          optionValue.DisplayName.toLowerCase().includes(filterValue) ||
          optionValue.UID.toLowerCase().includes(filterValue),
      );
    }
  }

  onTagRemove(tag: NbTagComponent) {
    const lock = this.myLocks.find(value => value.Id === tag.role);
    this.selectedLocksSet.forEach(v =>
      v.Name === lock.DisplayName && v.Id === lock.Id && v.Removable === true
        ? this.selectedLocksSet.delete(v)
        : v,
    );
    this.availableLocks.push(lock);
    this.filteredLocks$ = of(this.availableLocks);
    this.selectedLocksChange.emit(this.selectedLocksSet);
  }

  onTagAdd(id: string) {
    if (id) {
      const lock = this.myLocks.find(l => l.Id === id);
      this.selectedLocksSet.add({ Name: lock.DisplayName, Id: lock.Id, Removable: true });
      this.availableLocks = this.availableLocks.filter(v => v.Id !== id);
      this.filteredLocks$ = of(this.availableLocks);
      this.selectedLocksChange.emit(this.selectedLocksSet);
      this.tagInput.nativeElement.value = '';
    }
  }

  onModelChange(value: string) {
    this.filteredLocks$ = of(this.filter(value));
  }
}
