/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AdminPermissionRequestModel { 
    ResourceIds?: Array<string>;
    GrantedTo?: string;
    GrantedBy?: string;
    GrantedOnStart?: Date;
    GrantedOnEnd?: Date;
    Type?: AdminPermissionRequestModel.TypeEnum;
    Offset?: number;
    Limit?: number;
}
export namespace AdminPermissionRequestModel {
    export type TypeEnum = 'View' | 'Admin' | 'OneTime' | 'Maintenance' | 'Restricted';
    export const TypeEnum = {
        View: 'View' as TypeEnum,
        Admin: 'Admin' as TypeEnum,
        OneTime: 'OneTime' as TypeEnum,
        Maintenance: 'Maintenance' as TypeEnum,
        Restricted: 'Restricted' as TypeEnum
    };
}
