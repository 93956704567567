import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  HubDetailsAdminModel,
  HubEditModel,
  HubsAdminService,
  Lock,
  NewHubModel,
} from '../../lockvue-ng-sdk';
import { LockvueConfigProviderService } from '../../services/lockvue-config-provider.service';
import { NotificationMessagesService } from '../../services/notification-messages.service';

@Component({
  selector: 'lockvue-admin-new-hub',
  templateUrl: './admin-new-hub.component.html',
  styleUrls: ['./admin-new-hub.component.scss'],
})
export class AdminNewHubComponent implements OnInit, OnDestroy {
  detailsForm: FormGroup;
  availableFilter: string = '';
  filteredLocks$: Observable<Lock[]>;
  allLocks: Lock[];
  locksInHub = [];
  destroy$ = new Subject();
  hubTypes: string[];
  isEditing = false;
  private hubId: string;

  @Input() set selectedHub(hub: HubDetailsAdminModel) {
    this.isEditing = true;
    this.hubId = hub.HubId;

    this.detailsForm.addControl('IsActive', new FormControl(hub.IsActive, Validators.required));
    this.detailsForm.removeControl('ActivateOnCreation');

    this.detailsForm.setValue({
      Name: hub.Name,
      Description: hub.Description,
      HubType: hub.HubType,
      TotalRows: hub.TotalRows,
      TotalColumns: hub.TotalColumns,
      UID: hub.UID,
      HWAddress: hub.HWAddress,
      OfflineEnabeled: hub.OfflineEnabled,
      IsActive: hub.IsActive,
      NotBilled: hub.NotBilled,
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private hubsAdminService: HubsAdminService,
    private notificationService: NotificationMessagesService,
    private configProviderService: LockvueConfigProviderService,
  ) {
    this.detailsForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Description: [''],
      HubType: ['', Validators.required],
      TotalRows: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      TotalColumns: [14, [Validators.required, Validators.pattern('^[0-9]*$')]],
      ActivateOnCreation: [true, Validators.required],
      UID: ['', Validators.required],
      HWAddress: ['', Validators.required],
      OfflineEnabeled: [false, Validators.required],
      NotBilled: [true, Validators.required],
    });
  }

  ngOnInit(): void {
    this.hubTypes = this.configProviderService.getHubTypes();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  detailsSubmit() {
    this.detailsForm.markAsDirty();
    if (this.detailsForm.valid) {
      const newHub = this.detailsForm.value as NewHubModel;
      this.hubsAdminService
        .hubsAdminAdd(newHub)
        .pipe(takeUntil(this.destroy$))
        .subscribe(x => {
          this.notificationService.showSuccess('Hub Added', 'New Hub has been added.');
          this.detailsForm.reset();
        });
    }
  }

  editSubmit() {
    this.detailsForm.markAsDirty();
    if (this.detailsForm.valid) {
      const editHub = this.detailsForm.value as HubEditModel;
      editHub.HubId = this.hubId;
      this.hubsAdminService
        .hubsAdminEdit(editHub)
        .pipe(takeUntil(this.destroy$))
        .subscribe(x => {
          this.notificationService.showSuccess('Hub Edited', 'You can close the window now.');
        });
    }
  }
}
