import { Inject, Injectable, Renderer2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UmamiService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public initialize(renderer: Renderer2): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.src = 'https://analytics.umami.is/script.js';
    script.setAttribute('data-website-id', environment.umamiWebsiteId);
    script.async = true;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  public track(name: string, payload?: any) {
    // @ts-ignore
    if (typeof umami === undefined) {
      // @ts-ignore
      umami.track(name, payload);
    }
  }
}
