<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>

<nb-alert *ngIf="errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">
      {{ message }}
    </li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input
      nbInput
      fieldSize="large"
      fullWidth
      [(ngModel)]="email"
      #e="ngModel"
      name="email"
      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
      placeholder="Email Address"
      autofocus
      required
      [status]="e.dirty ? (e.invalid ? 'danger' : 'success') : 'basic'" />
    <ng-container *ngIf="e.invalid && e.touched">
      <p class="caption status-danger" *ngIf="e.errors?.required">Email is required!</p>
      <p class="caption status-danger" *ngIf="e.errors?.pattern">Email should be the real one!</p>
    </ng-container>
  </div>

  <section *ngIf="!showAlias" class="another-action d-flex justify-content-end">
    <div>
      Using a
      <a class="text-link" routerLink="" (click)="showAlias = true">shared account?</a>
    </div>
  </section>
  <div *ngIf="showAlias" class="form-control-group">
    <label class="label" for="input-alias">Alias:</label>
    <input
      nbInput
      fieldSize="large"
      fullWidth
      [(ngModel)]="alias"
      #a="ngModel"
      name="alias"
      placeholder="Alias (e.g. Joe Bloggs)" />
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Password:</label>
    </span>
    <input
      nbInput
      fieldSize="large"
      fullWidth
      [(ngModel)]="password"
      #p="ngModel"
      name="password"
      type="password"
      placeholder="Password"
      [status]="p.dirty ? (p.invalid ? 'danger' : 'success') : 'basic'"
      required
      [minlength]="7"
      [maxlength]="50" />
    <ng-container *ngIf="p.invalid && p.touched">
      <p class="caption status-danger" *ngIf="p.errors?.required">Password is required!</p>
      <p class="caption status-danger" *ngIf="p.errors?.minlength || p.errors?.maxlength">
        Password should contain from 7 to 50 characters
      </p>
    </ng-container>
  </div>

  <button
    nbButton
    fullWidth
    status="primary"
    size="large"
    [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted">
    Log In
  </button>
</form>

<section class="another-action d-flex justify-content-around" aria-label="Register">
  <div>
    Don't have an account?
    <a class="text-link" routerLink="../register">Register</a>
  </div>
  <div>
    Can't Login?
    <a class="text-link" routerLink="../account-reset">Click Here</a>
  </div>
</section>
