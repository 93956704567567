import { Inject, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UAParser } from 'ua-parser-js';
import { LockvueConfigProviderService } from '../services/lockvue-config-provider.service';
import { LocalStorageService } from '../services/local-storage.service';
import { LocalStorageKeys } from '../services/constants/LocalStorageKeys';

@Injectable()
export class LockvueInterceptor implements HttpInterceptor {
  private uaParsed: UAParser.IResult = UAParser(window.navigator.userAgent);
  constructor(
    private configProvider: LockvueConfigProviderService,
    private localStorageService: LocalStorageService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const os = `${this.uaParsed.os.name} v${this.uaParsed.os.version}`;
    const browser = `${this.uaParsed.browser.name} v${this.uaParsed.browser.version}`;
    const appVersion = `${os}, LockVue Web v${this.configProvider.getDashboardVersion()}, ${browser}`;
    const alias = this.localStorageService.getItem(LocalStorageKeys.Alias);

    const headers: { [headerName: string]: string } = {
      'X-Astute-ClientPlatform': 'Web',
      'X-Astute-App-Version': appVersion,
    };
    if (alias) {
      headers['X-Astute-User-Alias'] = alias;
    }

    req = req.clone({ setHeaders: headers });
    return next.handle(req);
  }
}
