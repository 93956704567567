import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

type ResourceAutoSelectType = {
  DisplayName: string;
  UID: string;
  Id: string;
};
@Component({
  selector: 'lockvue-resource-filter',
  templateUrl: './resource-filter.component.html',
  styleUrls: ['./resource-filter.component.scss'],
})
export class ResourceFilterComponent {
  constructor() {}

  private _myResources: ResourceAutoSelectType[] = [];

  filteredOptions$: Observable<ResourceAutoSelectType[]>;

  @Input() set resources(_myResources: ResourceAutoSelectType[]) {
    this._myResources = _myResources;
    this.filteredOptions$ = of(_myResources);
  }

  get resources() {
    return this._myResources;
  }

  @Input() placeholder: string = '';
  @Output() resourceFilterChange = new EventEmitter<string>();

  @ViewChild('autoInput') input;

  private filter(string: string) {
    const filterString = string.toLowerCase();
    return this.resources.filter(
      locks =>
        locks.DisplayName.toLowerCase().includes(filterString) ||
        locks.UID.toLowerCase().includes(filterString),
    );
  }

  private getFilteredOptions(value: string) {
    return of(value).pipe(map(filter => this.filter(filter)));
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
    if (!this.input.nativeElement.value) {
      this.resourceFilterChange.emit(undefined);
    }
  }

  selectedChange($event: string) {
    this.resourceFilterChange.emit($event);
    const lock = this.resources.find(l => l.Id === $event);
    this.input.nativeElement.value = lock.DisplayName;
  }
}
