/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseLockEditModel } from '../model/baseLockEditModel';
import { LockActivationModel } from '../model/lockActivationModel';
import { LockDetailsModel } from '../model/lockDetailsModel';
import { LockDetailsWithPermissionModel } from '../model/lockDetailsWithPermissionModel';
import { LockOfflineFlagModel } from '../model/lockOfflineFlagModel';
import { LockShareModel } from '../model/lockShareModel';
import { LockShareWithTeamModel } from '../model/lockShareWithTeamModel';
import { LockStatusUpdateModel } from '../model/lockStatusUpdateModel';
import { UnlockDetailsModel } from '../model/unlockDetailsModel';
import { UnlockRequestModel } from '../model/unlockRequestModel';
import { UnlockedNotifyModel } from '../model/unlockedNotifyModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LocksService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activates a lock.
     * 
     * @param LockActivationModel Object containing lock activation details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksActivate(LockActivationModel: LockActivationModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locksActivate(LockActivationModel: LockActivationModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locksActivate(LockActivationModel: LockActivationModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public locksActivate(LockActivationModel: LockActivationModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LockActivationModel === null || LockActivationModel === undefined) {
            throw new Error('Required parameter LockActivationModel was null or undefined when calling locksActivate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/Locks/Activate`,
            LockActivationModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the lock identified by the given unique lock id.    Note: The caller must be the owner of the lock
     * 
     * @param Id A valid lock id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksDelete(Id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksDelete(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksDelete(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksDelete(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling locksDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/Locks/Delete/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates lock details with the given new values. Please note that all existing values  will be replaced by the new values (including nulls).    Please note that the user must be either the owner or an administrator of the lock.
     * 
     * @param EditModel Model containing new values. Please copy existing values if no update is intended.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksEdit(EditModel: BaseLockEditModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksEdit(EditModel: BaseLockEditModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksEdit(EditModel: BaseLockEditModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksEdit(EditModel: BaseLockEditModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (EditModel === null || EditModel === undefined) {
            throw new Error('Required parameter EditModel was null or undefined when calling locksEdit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Locks/Edit`,
            EditModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs the \&quot;Lock\&quot; event for the given lock. The caller must be authorized to use the lock to log this event.
     * 
     * @param Id Valid Lock Id of an authorized lock
     * @param UnlockSessionId Unlock session id (aka transaction id) as              provided with request unlock API call.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksLocked(Id: string, UnlockSessionId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksLocked(Id: string, UnlockSessionId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksLocked(Id: string, UnlockSessionId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksLocked(Id: string, UnlockSessionId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling locksLocked.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (UnlockSessionId !== undefined && UnlockSessionId !== null) {
            queryParameters = queryParameters.set('unlockSessionId', <any>UnlockSessionId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/Locks/Locked/${encodeURIComponent(String(Id))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * &lt;b&gt;Deprecated.&lt;/b&gt;              Use the Edit action instead.                            Enables or disables offline functionality for a given lock.              The caller must be either an admin or the owner of the lock to set offline flag.
     * 
     * @param Model Model containing a valid Lock-Id of an authorized lock and the offline enabled flag value
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksOfflineEnabled(Model: LockOfflineFlagModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksOfflineEnabled(Model: LockOfflineFlagModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksOfflineEnabled(Model: LockOfflineFlagModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksOfflineEnabled(Model: LockOfflineFlagModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksOfflineEnabled.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Locks/OfflineEnabled`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all locks owned by the current user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksOwn(observe?: 'body', reportProgress?: boolean): Observable<Array<LockDetailsModel>>;
    public locksOwn(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockDetailsModel>>>;
    public locksOwn(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockDetailsModel>>>;
    public locksOwn(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LockDetailsModel>>(`${this.basePath}/api/Locks/Own`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request unlock for an authorized lock from a verified app.
     * 
     * @param UnlockModel Object containing lock details required for unlocking the lock.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksRequestUnlock(UnlockModel: UnlockRequestModel, observe?: 'body', reportProgress?: boolean): Observable<UnlockDetailsModel>;
    public locksRequestUnlock(UnlockModel: UnlockRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UnlockDetailsModel>>;
    public locksRequestUnlock(UnlockModel: UnlockRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UnlockDetailsModel>>;
    public locksRequestUnlock(UnlockModel: UnlockRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UnlockModel === null || UnlockModel === undefined) {
            throw new Error('Required parameter UnlockModel was null or undefined when calling locksRequestUnlock.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UnlockDetailsModel>(`${this.basePath}/api/Locks/RequestUnlock`,
            UnlockModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares the lock with another user with given permissions
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksShare(Model: LockShareModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksShare(Model: LockShareModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksShare(Model: LockShareModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksShare(Model: LockShareModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksShare.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Locks/Share`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares the lock with another user with given permissions using an auth-code  without requiring the user&#39;s email address.    Please note that you cannot share a lock with a unique code using admin permissions.
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksShareWithCode(Model: LockShareModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksShareWithCode(Model: LockShareModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksShareWithCode(Model: LockShareModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksShareWithCode(Model: LockShareModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksShareWithCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Locks/ShareWithCode`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares the lock with the users in a team
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksShareWithTeam(Model: LockShareWithTeamModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksShareWithTeam(Model: LockShareWithTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksShareWithTeam(Model: LockShareWithTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksShareWithTeam(Model: LockShareWithTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksShareWithTeam.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Locks/ShareWithTeam`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all locks shared with the current user by their owners
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksSharedWithMe(observe?: 'body', reportProgress?: boolean): Observable<Array<LockDetailsWithPermissionModel>>;
    public locksSharedWithMe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockDetailsWithPermissionModel>>>;
    public locksSharedWithMe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockDetailsWithPermissionModel>>>;
    public locksSharedWithMe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LockDetailsWithPermissionModel>>(`${this.basePath}/api/Locks/SharedWithMe`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notifies the system of a successful unlock event for a previously requested unlock.
     * 
     * @param UnlockedModel Object containing details required for registering a successful unlock event.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksUnlocked(UnlockedModel: UnlockedNotifyModel, observe?: 'body', reportProgress?: boolean): Observable<UnlockDetailsModel>;
    public locksUnlocked(UnlockedModel: UnlockedNotifyModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UnlockDetailsModel>>;
    public locksUnlocked(UnlockedModel: UnlockedNotifyModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UnlockDetailsModel>>;
    public locksUnlocked(UnlockedModel: UnlockedNotifyModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UnlockedModel === null || UnlockedModel === undefined) {
            throw new Error('Required parameter UnlockedModel was null or undefined when calling locksUnlocked.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UnlockDetailsModel>(`${this.basePath}/api/Locks/Unlocked`,
            UnlockedModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the lock status information for the given lock.
     * 
     * @param Model Model containing lock status information.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksUpdateStatus(Model: LockStatusUpdateModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksUpdateStatus(Model: LockStatusUpdateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksUpdateStatus(Model: LockStatusUpdateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksUpdateStatus(Model: LockStatusUpdateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksUpdateStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Locks/UpdateStatus`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
