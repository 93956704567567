/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddMemberToTeamModel } from '../model/addMemberToTeamModel';
import { MemberInTeamModel } from '../model/memberInTeamModel';
import { NewTeamModel } from '../model/newTeamModel';
import { PermissionDetailsV2 } from '../model/permissionDetailsV2';
import { TeamDetailsModel } from '../model/teamDetailsModel';
import { TeamEditModel } from '../model/teamEditModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class TeamsService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds a new team to the system.
     * 
     * @param Team Object containing basic team details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsAdd(Team: NewTeamModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public teamsAdd(Team: NewTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public teamsAdd(Team: NewTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public teamsAdd(Team: NewTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Team === null || Team === undefined) {
            throw new Error('Required parameter Team was null or undefined when calling teamsAdd.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/Teams/Add`,
            Team,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a new user in the given team.  Note that the team must be owned by the current user.
     * 
     * @param Model Object containing a valid team-id and a valid user-id and other details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsAddMember(Model: AddMemberToTeamModel, observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionDetailsV2>>;
    public teamsAddMember(Model: AddMemberToTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionDetailsV2>>>;
    public teamsAddMember(Model: AddMemberToTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionDetailsV2>>>;
    public teamsAddMember(Model: AddMemberToTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling teamsAddMember.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<PermissionDetailsV2>>(`${this.basePath}/api/Teams/AddMember`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the team identified by the given unique team id.  Note that only the owner of the resource can delete it.
     * 
     * @param Id A valid team id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsDelete(Id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public teamsDelete(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public teamsDelete(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public teamsDelete(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling teamsDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/Teams/Delete/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the team details for the team identified by the given team id
     * 
     * @param Id Unique valid team id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsDetails(Id: string, observe?: 'body', reportProgress?: boolean): Observable<TeamDetailsModel>;
    public teamsDetails(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeamDetailsModel>>;
    public teamsDetails(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeamDetailsModel>>;
    public teamsDetails(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling teamsDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TeamDetailsModel>(`${this.basePath}/api/Teams/Details/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates team details with the given new values. Please note that all existing values  will be replaced by the new values (including nulls).    Please note that the user must be the owner of the team.
     * 
     * @param EditModel Model containing new values. Please copy existing values if no update is intended.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsEdit(EditModel: TeamEditModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public teamsEdit(EditModel: TeamEditModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public teamsEdit(EditModel: TeamEditModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public teamsEdit(EditModel: TeamEditModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (EditModel === null || EditModel === undefined) {
            throw new Error('Required parameter EditModel was null or undefined when calling teamsEdit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Teams/Edit`,
            EditModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all teams owned by the current user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsOwn(observe?: 'body', reportProgress?: boolean): Observable<Array<TeamDetailsModel>>;
    public teamsOwn(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeamDetailsModel>>>;
    public teamsOwn(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeamDetailsModel>>>;
    public teamsOwn(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TeamDetailsModel>>(`${this.basePath}/api/Teams/Own`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes the given user from the given team.  Note that the team must be owned by the current user.
     * 
     * @param Model Object containing a valid team-id and a valid user-id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsRemoveMember(Model: MemberInTeamModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public teamsRemoveMember(Model: MemberInTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public teamsRemoveMember(Model: MemberInTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public teamsRemoveMember(Model: MemberInTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling teamsRemoveMember.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Teams/RemoveMember`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
