<nb-card>
  <nb-card-header>
    Sharing:
    <span *ngFor="let l of locks.slice(0, 2); let i = index">
      {{ i > 0 ? ', ' : '' }}
      {{ l.DisplayName }}
    </span>
    <span *ngIf="locks.length > 2"> and {{ locks.length - 2 }} more. </span>
  </nb-card-header>
  <nb-card-body>
    <nb-stepper #stepper orientation="horizontal" [disableStepNavigation]="true">
      <nb-step label="Recipient">
        <div class="row">
          <div class="col-md-6">
            <h6>Recipient</h6>
            <nb-radio-group
              [(ngModel)]="lockShareDetails.LockShareType"
              (valueChange)="lockShareDetails.Recipient = null">
              <nb-radio [value]="LockShareType.Individual"> Individual </nb-radio>
              <nb-radio [value]="LockShareType.Team"> Team </nb-radio>
              <nb-radio
                *ngIf="userProfile && userProfile.EnableCodes"
                [value]="LockShareType.UniqueCode">
                Unique Code
              </nb-radio>
            </nb-radio-group>
          </div>
          <!-- <div class='col-md-6' *ngIf='integrationsAllowed && userProfile &&
          (userProfile.EnableHOMheroIntegration || userProfile.EnableYBIIntegration)'>
            <h6>Integrations</h6>
            <div *ngIf='integrationsAllowed && userProfile && (userProfile.EnableHOMheroIntegration ||
              userProfile.EnableYBIIntegration)'>
              <nb-radio-group [(ngModel)]="lockShareDetails.LockShareType"
              (valueChange)='lockShareDetails.Recipient = null'>
                <nb-radio *ngIf='userProfile && userProfile.EnableHOMheroIntegration'
                  [value]="LockShareType.IntegrationPartner_HOMhero">
                  HOMhero
                </nb-radio>
              </nb-radio-group>
            </div>
          </div> -->
        </div>
        <br />
        <div>
          <input
            nbInput
            [(ngModel)]="lockShareDetails.Recipient"
            type="email"
            placeholder="Recipient's Email Address"
            [fullWidth]="true"
            required
            email
            #email="ngModel"
            [status]="email?.touched && email?.invalid ? 'danger' : 'primary'"
            *ngIf="lockShareDetails.LockShareType === LockShareType.Individual"
            (keydown.enter)="
              !(email?.touched && email?.invalid) &&
              userProfile &&
              userProfile.UserId !== lockShareDetails.Recipient
                ? stepper.next()
                : null
            " />
          <ng-container>
            <p
              *ngIf="userProfile && lockShareDetails.Recipient === userProfile.UserId"
              class="mt-2 p-0 caption status-danger">
              You can't share lock to your own email address!
            </p>
          </ng-container>
          <nb-select
            [(ngModel)]="lockShareDetails.Recipient"
            placeholder="Select a Team"
            [fullWidth]="true"
            *ngIf="lockShareDetails.LockShareType === LockShareType.Team"
            (keydown.enter)="lockShareDetails.Recipient ? stepper.next() : null">
            <nb-option *ngFor="let team of myTeams" [value]="team.Id">
              {{ team.Name }}&nbsp;<small
                >({{ team.Members.length }}
                {{ team.Members.length === 1 ? 'Member' : 'Members' }})</small
              >
            </nb-option>
          </nb-select>
          <nb-select
            [(ngModel)]="lockShareDetails.Recipient"
            placeholder="Select a Unique Code"
            [fullWidth]="true"
            *ngIf="lockShareDetails.LockShareType === LockShareType.UniqueCode"
            (keydown.enter)="lockShareDetails.Recipient ? stepper.next() : null">
            <nb-option *ngFor="let code of activeCodes" [value]="code.Code">
              {{ code.Code }}&nbsp;<small *ngIf="code.Description && code.Description.length > 0">
                ({{ code.Description.slice(0, 20) }}{{ code.Description.length > 20 ? '...' : '' }})
              </small>
            </nb-option>
          </nb-select>
        </div>
        <div class="wizard-nav-buttons">
          <button
            nbButton
            nbStepperNext
            [disabled]="
              lockShareDetails.LockShareType !== LockShareType.IntegrationPartner_HOMhero &&
              (!lockShareDetails.Recipient ||
                (userProfile && userProfile.UserId === lockShareDetails.Recipient))
            ">
            Next
          </button>
        </div>
      </nb-step>
      <nb-step
        label="Permission Type"
        *ngIf="
          lockShareDetails.LockShareType !== LockShareType.IntegrationPartner_YBI &&
          lockShareDetails.LockShareType !== LockShareType.IntegrationPartner_HOMhero
        ">
        <h6>Permission Type</h6>
        <div>
          <nb-radio-group [(ngModel)]="lockShareDetails.PermissionType">
            <nb-radio [value]="permissionTypes.Admin">
              Admin
              <nb-icon
                size="large"
                icon="ion-ios-information-outline"
                (click)="permissionHelp(permissionTypes.Admin)"></nb-icon>
            </nb-radio>
            <!-- <nb-radio [value]="permissionTypes.View">
              View
            </nb-radio> -->
            <nb-radio [value]="permissionTypes.Maintenance">
              Maintenance
              <nb-icon
                size="large"
                icon="ion-ios-information-outline"
                (click)="permissionHelp(permissionTypes.Maintenance)"></nb-icon>
            </nb-radio>
            <nb-radio [value]="permissionTypes.Restricted">
              Restricted
              <nb-icon
                size="large"
                icon="ion-ios-information-outline"
                (click)="permissionHelp(permissionTypes.Restricted)"></nb-icon>
            </nb-radio>
            <nb-radio [value]="permissionTypes.OneTime">
              One-Time
              <nb-icon
                size="large"
                icon="ion-ios-information-outline"
                (click)="permissionHelp(permissionTypes.OneTime)"></nb-icon>
            </nb-radio>
          </nb-radio-group>
        </div>
        <div class="wizard-nav-buttons">
          <button nbButton nbStepperNext>Next</button>
        </div>
      </nb-step>
      <nb-step label="Time Restrictions" *ngIf="lockShareDetails.TimeRestrictionEnabled">
        <h6>Time Restrictions</h6>
        <div>
          <div class="col-md-12 form-group">
            <label for="permissionStartDateTime">From</label>
            <div>
              <a
                role="button"
                class="datetime-input"
                (click)="endDateTimeVisible = false; startDateTimeVisible = !startDateTimeVisible">
                <div class="input-group">
                  <span class="form-control" id="permissionStartDateTime">
                    <span *ngIf="lockShareDetails.TimeRestrictionStart">
                      {{ lockShareDetails.TimeRestrictionStart | date : 'dd-MMM-yyyy hh:mm a' }}
                    </span>
                    <span *ngIf="!lockShareDetails.TimeRestrictionStart">Now</span>
                  </span>
                  <span class="input-group-addon"><i class="fa icon-calendar"></i></span>
                </div>
              </a>
              <ul
                [className]="startDateTimeVisible ? 'dropdown-menu show' : 'dropdown-menu'"
                id="dateTimePickerShareModalStartDate"
                role="menu"
                aria-labelledby="dLabel">
                <dl-date-time-picker
                  startView="day"
                  maxView="year"
                  minView="minute"
                  minuteStep="5"
                  [(ngModel)]="lockShareDetails.TimeRestrictionStart"
                  (change)="onStartDateTimeChange()">
                </dl-date-time-picker>
              </ul>
            </div>
          </div>
          <div class="col-md-12 form-group">
            <label for="permissionEndDateTime">To</label>
            <div>
              <a
                role="button"
                (click)="startDateTimeVisible = false; endDateTimeVisible = !endDateTimeVisible"
                class="datetime-input">
                <div class="input-group">
                  <span class="form-control">
                    <span *ngIf="lockShareDetails.TimeRestrictionEnd">
                      {{ lockShareDetails.TimeRestrictionEnd | date : 'dd-MMM-yyyy hh:mm a' }}
                    </span>
                    <span *ngIf="!lockShareDetails.TimeRestrictionEnd">Indefinite</span>
                  </span>
                  <span class="input-group-addon"><i class="fa icon-calendar"></i></span>
                </div>
              </a>
              <ul
                [className]="endDateTimeVisible ? 'dropdown-menu show' : 'dropdown-menu'"
                id="dateTimePickerShareModalEndDate"
                role="menu"
                aria-labelledby="dLabel">
                <dl-date-time-picker
                  startView="day"
                  maxView="year"
                  minView="minute"
                  minuteStep="5"
                  [(ngModel)]="lockShareDetails.TimeRestrictionEnd"
                  (change)="onEndDateTimeChange()">
                </dl-date-time-picker>
              </ul>
            </div>
          </div>

          <div
            class="col-md-12 form-group"
            *ngIf="lockShareDetails.PermissionType === permissionTypes.OneTime">
            <label for="onetimeAccessWindowMinInput">One-Time Access Window (in minutes)</label>
            <div>
              <input
                nbInput
                [(ngModel)]="lockShareDetails.OneTimeWindowMins"
                [fullWidth]="true"
                id="onetimeAccessWindowMinInput"
                type="number"
                min="1"
                max="60" />
            </div>
          </div>
        </div>
        <div class="wizard-nav-buttons">
          <button nbButton nbStepperNext (click)="shareLocks()">Share</button>
        </div>
      </nb-step>
      <nb-step label="Result">
        <h6>Result</h6>
        <div class="results">
          <div *ngIf="lockShareSuccessCount === 0 && lockShareFailureCount === 0">
            Please wait...
          </div>
          <div *ngIf="lockShareSuccessCount > 0" class="sucess">
            <nb-alert outline="success">
              {{ lockShareSuccessCount }} out of {{ locks.length }} locks shared successfully.
            </nb-alert>
          </div>
          <div *ngIf="lockShareFailureCount > 0">
            <nb-alert outline="danger">
              <b>Error!</b>
              {{ lockShareFailureCount }} out of {{ locks.length }} locks could not be shared.
            </nb-alert>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>
