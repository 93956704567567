/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Datapoint } from './datapoint';


export interface DataSeries { 
    Name?: string;
    Datatype?: DataSeries.DatatypeEnum;
    Datapoints?: Array<Datapoint>;
    AggregationType?: DataSeries.AggregationTypeEnum;
    IsRunningTotal?: boolean;
}
export namespace DataSeries {
    export type DatatypeEnum = 'Unlocks' | 'ActiveUsers' | 'Registrations' | 'AppRegistrations' | 'Requests' | 'LockActivations' | 'LockShares';
    export const DatatypeEnum = {
        Unlocks: 'Unlocks' as DatatypeEnum,
        ActiveUsers: 'ActiveUsers' as DatatypeEnum,
        Registrations: 'Registrations' as DatatypeEnum,
        AppRegistrations: 'AppRegistrations' as DatatypeEnum,
        Requests: 'Requests' as DatatypeEnum,
        LockActivations: 'LockActivations' as DatatypeEnum,
        LockShares: 'LockShares' as DatatypeEnum
    };
    export type AggregationTypeEnum = 'Hourly' | 'Daily' | 'Monthly';
    export const AggregationTypeEnum = {
        Hourly: 'Hourly' as AggregationTypeEnum,
        Daily: 'Daily' as AggregationTypeEnum,
        Monthly: 'Monthly' as AggregationTypeEnum
    };
}
