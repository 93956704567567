/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LockDetailsRestricted } from './lockDetailsRestricted';


export interface MyUpcomingLockDetails { 
    PermissionType?: MyUpcomingLockDetails.PermissionTypeEnum;
    ActivationTimeUtc?: Date;
    DeactivationTimeUtc?: Date;
    OneTimeAccessWindowMins?: number;
    GrantedBy?: string;
    GrantedOnUTC?: Date;
    LockDetails?: LockDetailsRestricted;
}
export namespace MyUpcomingLockDetails {
    export type PermissionTypeEnum = 'View' | 'Admin' | 'OneTime' | 'Maintenance' | 'Restricted';
    export const PermissionTypeEnum = {
        View: 'View' as PermissionTypeEnum,
        Admin: 'Admin' as PermissionTypeEnum,
        OneTime: 'OneTime' as PermissionTypeEnum,
        Maintenance: 'Maintenance' as PermissionTypeEnum,
        Restricted: 'Restricted' as PermissionTypeEnum
    };
}
