/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdminLockActivationModel } from '../model/adminLockActivationModel';
import { BillableFlagModel } from '../model/billableFlagModel';
import { LockDetailsWithOwnerModel } from '../model/lockDetailsWithOwnerModel';
import { LockReplaceModel } from '../model/lockReplaceModel';
import { ManualUnlockCodeRequestModel } from '../model/manualUnlockCodeRequestModel';
import { OwnershipTransferAdminModel } from '../model/ownershipTransferAdminModel';
import { SmartArmorSecondaryKeyModel } from '../model/smartArmorSecondaryKeyModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LocksAdminService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activates a valid available lock against the given user
     * 
     * @param Model Model containing valid lock activation properties.  Important: LockType must be the valid \&quot;UnlockAlgorithm\&quot; value of the lock.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminActivate(Model: AdminLockActivationModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locksAdminActivate(Model: AdminLockActivationModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locksAdminActivate(Model: AdminLockActivationModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public locksAdminActivate(Model: AdminLockActivationModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksAdminActivate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/admin/Locks/Activate`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminAll(observe?: 'body', reportProgress?: boolean): Observable<Array<LockDetailsWithOwnerModel>>;
    public locksAdminAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockDetailsWithOwnerModel>>>;
    public locksAdminAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockDetailsWithOwnerModel>>>;
    public locksAdminAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Array<LockDetailsWithOwnerModel>>(`${this.basePath}/api/admin/Locks/All`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Makes an existing activated lock billable or non-billable
     * 
     * @param Model Model containing a valid lock-id and the billable flag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminBillable(Model: BillableFlagModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminBillable(Model: BillableFlagModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminBillable(Model: BillableFlagModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminBillable(Model: BillableFlagModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksAdminBillable.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/Locks/Billable`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the manual unlock code such as the quick-click code of a lock.
     * 
     * @param Id A valid lock-id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminGetManualUnlockCode(Id: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locksAdminGetManualUnlockCode(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locksAdminGetManualUnlockCode(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public locksAdminGetManualUnlockCode(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling locksAdminGetManualUnlockCode.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Id !== undefined && Id !== null) {
            queryParameters = queryParameters.set('id', <any>Id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/admin/Locks/ManualUnlockCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replaces an existing lock with the new lock while keeping the lock-id  and all lock permissions and other relationships intact.    Note: Some relationships may need to be manually updated such as associated Hubs.
     * 
     * @param Model Model containing lock replacement details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminReplaceLock(Model: LockReplaceModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminReplaceLock(Model: LockReplaceModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminReplaceLock(Model: LockReplaceModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminReplaceLock(Model: LockReplaceModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksAdminReplaceLock.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/Locks/Replace`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the manual unlock code such as the quick-click code of a lock.
     * 
     * @param Model Model containing manual unlock code and a valid lock-id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminSetManualUnlockCode(Model: ManualUnlockCodeRequestModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminSetManualUnlockCode(Model: ManualUnlockCodeRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminSetManualUnlockCode(Model: ManualUnlockCodeRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminSetManualUnlockCode(Model: ManualUnlockCodeRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksAdminSetManualUnlockCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/Locks/ManualUnlockCode`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the Secondary Key (IV) for an existing SmartArmor lock in the pool of available locks.
     * 
     * @param Model Object containing lock details including lock Serial Number and the new IV
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminSetSmartArmorIV(Model: SmartArmorSecondaryKeyModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminSetSmartArmorIV(Model: SmartArmorSecondaryKeyModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminSetSmartArmorIV(Model: SmartArmorSecondaryKeyModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminSetSmartArmorIV(Model: SmartArmorSecondaryKeyModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksAdminSetSmartArmorIV.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/Locks/SmartArmor/SecondaryKey`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transfers lock ownership to a new user
     * 
     * @param Model Model containing LockId, OwnerId and NewOwnerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling locksAdminTransferOwnership.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/Locks/Ownership/Change`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
