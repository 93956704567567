import { Component, OnInit } from '@angular/core';
import { TeamMemberModel } from '../../lockvue-ng-sdk';
import { DefaultEditor } from 'ng2-smart-table';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'lockvue-team-members-list',
  templateUrl: './team-members-list.component.html',
  styleUrls: ['./team-members-list.component.scss'],
})
export class TeamMembersListComponent extends DefaultEditor {
  // add action is performed when these keys are pressed
  readonly SeparatorKeyCodes = [13, 188]; // comma and enter keys

  constructor() {
    super();
  }

  getTeamId(): string {
    return this.cell.getRow().getData().Id;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add new team member
    if ((value || '').trim()) {
      const newTeamMember: TeamMemberModel = {
        UserId: value,
      };

      // TODO - make API call to add member
      this.cell.newValue.push(newTeamMember);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(member: TeamMemberModel): void {
    const index = this.cell.newValue.indexOf(member);

    if (index >= 0) {
      // TODO - make API call to remove member
      this.cell.newValue.splice(index, 1);
    }
  }
}
