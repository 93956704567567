/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MemberInTeamModel } from '../model/memberInTeamModel';
import { OwnershipTransferAdminModel } from '../model/ownershipTransferAdminModel';
import { TeamDetailsAdminModel } from '../model/teamDetailsAdminModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class TeamsAdminService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsAdminAll(observe?: 'body', reportProgress?: boolean): Observable<Array<TeamDetailsAdminModel>>;
    public teamsAdminAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeamDetailsAdminModel>>>;
    public teamsAdminAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeamDetailsAdminModel>>>;
    public teamsAdminAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Array<TeamDetailsAdminModel>>(`${this.basePath}/api/admin/Teams/All`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsAdminRemoveMember(Model: MemberInTeamModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public teamsAdminRemoveMember(Model: MemberInTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public teamsAdminRemoveMember(Model: MemberInTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public teamsAdminRemoveMember(Model: MemberInTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling teamsAdminRemoveMember.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/Teams/RemoveMember`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transfers team ownership to a new user
     * 
     * @param Model Model containing team Id, current owner UserId and new owner UserId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teamsAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public teamsAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public teamsAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public teamsAdminTransferOwnership(Model: OwnershipTransferAdminModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling teamsAdminTransferOwnership.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/Teams/Ownership/Change`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
