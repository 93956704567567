/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddLockToGroupModel } from '../model/addLockToGroupModel';
import { GroupDetailsModel } from '../model/groupDetailsModel';
import { GroupEditModel } from '../model/groupEditModel';
import { GroupShareModel } from '../model/groupShareModel';
import { GroupShareWithTeamModel } from '../model/groupShareWithTeamModel';
import { GroupWithLockDetailsModel } from '../model/groupWithLockDetailsModel';
import { LockInGroup } from '../model/lockInGroup';
import { NewGroupModel } from '../model/newGroupModel';
import { PermissionDetailsV2 } from '../model/permissionDetailsV2';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LockGroupsService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds a new group to the system.
     * 
     * @param Group Object containing basic group details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsAdd(Group: NewGroupModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public lockGroupsAdd(Group: NewGroupModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public lockGroupsAdd(Group: NewGroupModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public lockGroupsAdd(Group: NewGroupModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Group === null || Group === undefined) {
            throw new Error('Required parameter Group was null or undefined when calling lockGroupsAdd.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/LockGroups/Add`,
            Group,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds the given lock to the given group.  Note that both the group and the lock must be owned by the user.
     * 
     * @param LockInGroup Object containing a valid lock-id and a valid group-id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsAddLockToGroup(LockInGroup: AddLockToGroupModel, observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionDetailsV2>>;
    public lockGroupsAddLockToGroup(LockInGroup: AddLockToGroupModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionDetailsV2>>>;
    public lockGroupsAddLockToGroup(LockInGroup: AddLockToGroupModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionDetailsV2>>>;
    public lockGroupsAddLockToGroup(LockInGroup: AddLockToGroupModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LockInGroup === null || LockInGroup === undefined) {
            throw new Error('Required parameter LockInGroup was null or undefined when calling lockGroupsAddLockToGroup.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<PermissionDetailsV2>>(`${this.basePath}/api/LockGroups/AddLockToGroup`,
            LockInGroup,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the lock identified by the given unique lock id.  Note that only the owner of the resource can delete it.
     * 
     * @param Id A valid lock id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsDelete(Id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockGroupsDelete(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockGroupsDelete(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockGroupsDelete(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling lockGroupsDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/LockGroups/Delete/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns details of the group identified by the given group id
     * 
     * @param Id Unique group id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsDetails(Id: string, observe?: 'body', reportProgress?: boolean): Observable<GroupDetailsModel>;
    public lockGroupsDetails(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GroupDetailsModel>>;
    public lockGroupsDetails(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GroupDetailsModel>>;
    public lockGroupsDetails(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling lockGroupsDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GroupDetailsModel>(`${this.basePath}/api/LockGroups/Details/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates lock group details with the given new values. Please note that all existing values  will be replaced by the new values (including nulls).    Please note that the user must be the owner of the lock group.
     * 
     * @param EditModel Model containing new values. Please copy existing values if no update is intended.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsEdit(EditModel: GroupEditModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockGroupsEdit(EditModel: GroupEditModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockGroupsEdit(EditModel: GroupEditModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockGroupsEdit(EditModel: GroupEditModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (EditModel === null || EditModel === undefined) {
            throw new Error('Required parameter EditModel was null or undefined when calling lockGroupsEdit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/LockGroups/Edit`,
            EditModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all groups owned by the current user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsOwn(observe?: 'body', reportProgress?: boolean): Observable<Array<GroupWithLockDetailsModel>>;
    public lockGroupsOwn(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupWithLockDetailsModel>>>;
    public lockGroupsOwn(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupWithLockDetailsModel>>>;
    public lockGroupsOwn(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GroupWithLockDetailsModel>>(`${this.basePath}/api/LockGroups/Own`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes the given lock from the given group.  Note that the group must be owned by the user.
     * 
     * @param LockInGroup Object containing a valid lock-id and a valid group-id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsRemoveLockFromGroup(LockInGroup: LockInGroup, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockGroupsRemoveLockFromGroup(LockInGroup: LockInGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockGroupsRemoveLockFromGroup(LockInGroup: LockInGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockGroupsRemoveLockFromGroup(LockInGroup: LockInGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LockInGroup === null || LockInGroup === undefined) {
            throw new Error('Required parameter LockInGroup was null or undefined when calling lockGroupsRemoveLockFromGroup.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/LockGroups/RemoveLockFromGroup`,
            LockInGroup,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares a group of locks with another user with given permissions
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsShare(Model: GroupShareModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockGroupsShare(Model: GroupShareModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockGroupsShare(Model: GroupShareModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockGroupsShare(Model: GroupShareModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling lockGroupsShare.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/LockGroups/Share`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares a group of locks with another user with given permissions using an auth-code  without requiring the user&#39;s email address.    Please note that you cannot share a lock-group with a unique code using admin permissions.
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsShareWithCode(Model: GroupShareModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockGroupsShareWithCode(Model: GroupShareModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockGroupsShareWithCode(Model: GroupShareModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockGroupsShareWithCode(Model: GroupShareModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling lockGroupsShareWithCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/LockGroups/ShareWithCode`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares a group of locks with the users in a team
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsShareWithTeam(Model: GroupShareWithTeamModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockGroupsShareWithTeam(Model: GroupShareWithTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockGroupsShareWithTeam(Model: GroupShareWithTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockGroupsShareWithTeam(Model: GroupShareWithTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling lockGroupsShareWithTeam.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/LockGroups/ShareWithTeam`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares a group of locks with the users in a team optimized for really large groups and teams.   NOTE: Operation is performed in background and success is not guaranteed.
     * 
     * @param Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lockGroupsShareWithTeamV2(Model: GroupShareWithTeamModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public lockGroupsShareWithTeamV2(Model: GroupShareWithTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public lockGroupsShareWithTeamV2(Model: GroupShareWithTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public lockGroupsShareWithTeamV2(Model: GroupShareWithTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Model === null || Model === undefined) {
            throw new Error('Required parameter Model was null or undefined when calling lockGroupsShareWithTeamV2.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/LockGroups/ShareWithTeamV2`,
            Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
