/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { UserModel } from '../model/userModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class UsersPartnerAdminService {

    protected basePath = 'http://localhost:35181';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns all user profiles created between given start and end date-time (UTC) and for given client id.
     * 
     * @param StartTimeUTC Start time in UTC
     * @param EndTimeUTC End time in UTC
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersPartnerAdminRegistrations(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<UserModel>>;
    public usersPartnerAdminRegistrations(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserModel>>>;
    public usersPartnerAdminRegistrations(StartTimeUTC: Date, EndTimeUTC: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserModel>>>;
    public usersPartnerAdminRegistrations(StartTimeUTC: Date, EndTimeUTC: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (StartTimeUTC === null || StartTimeUTC === undefined) {
            throw new Error('Required parameter StartTimeUTC was null or undefined when calling usersPartnerAdminRegistrations.');
        }

        if (EndTimeUTC === null || EndTimeUTC === undefined) {
            throw new Error('Required parameter EndTimeUTC was null or undefined when calling usersPartnerAdminRegistrations.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (StartTimeUTC !== undefined && StartTimeUTC !== null) {
            queryParameters = queryParameters.set('startTimeUTC', <any>StartTimeUTC.toISOString());
        }
        if (EndTimeUTC !== undefined && EndTimeUTC !== null) {
            queryParameters = queryParameters.set('endTimeUTC', <any>EndTimeUTC.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Array<UserModel>>(`${this.basePath}/api/UsersPartnerAdmin/Registrations`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
