<div class="d-inline">
  <nb-button-group outline class="mb-2" size="small">
    <button
      nbButtonToggle
      data-umami-event="locks-all-locks"
      pressed
      (pressedChange)="defaultPressed($event)">
      All Locks
    </button>
    <button
      nbButtonToggle
      data-umami-event="locks-unlocked-status"
      (pressedChange)="lockStatusPressed($event)">
      Unlocked Status
    </button>
  </nb-button-group>
  <!-- <small class="ml-3" *ngIf="lockStatusView">
    You can only see Locks with Lock Type {{ lockStatusEnabledLockTypes$ | async }}
  </small> -->
</div>
<div class="table-container">
  <ng2-smart-table
    *ngIf="tableEnabled"
    [settings]="tableSettings"
    [source]="lockSource"
    (edit)="onEditRequest($event)"
    (rowHover)="onRowSelect($event)"
    (userRowSelect)="onUserRowSelect($event)">
  </ng2-smart-table>

  <ng-template #editLockTemplate let-lock>
    <lockvue-lock-edit [value]="lock" (lockUpdated)="onLockModified($event)"> </lockvue-lock-edit>
  </ng-template>
</div>
<div>
  <ngx-slide-out [open]="false">
    <span *ngIf="!lockOnFocus"> Please select a lock to view details. </span>

    <div *ngIf="lockOnFocus">
      <lockvue-lock-info [value]="lockOnFocus"></lockvue-lock-info>
    </div>
  </ngx-slide-out>
</div>
