<nb-tag-list (tagRemove)="onTagRemove($event)">
  <input
    placeholder="{{ text }}"
    type="text"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    nbInput
    nbTagInput
    fullWidth
    [nbAutocomplete]="auto" />
  <nb-tag
    *ngFor="let l of selectedLocksSet"
    [text]="l.Name"
    [role]="l.Id"
    [removable]="l.Removable"></nb-tag>
</nb-tag-list>

<nb-autocomplete #auto (selectedChange)="onTagAdd($event)">
  <nb-option *ngFor="let l of filteredLocks$ | async" value="{{ l.Id }}">
    {{ l.DisplayName }}&nbsp;<small class="text-muted">({{ l.UID }})</small>
  </nb-option>
</nb-autocomplete>
