/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LockDetailsModel } from './lockDetailsModel';
import { UserDetailsLimited } from './userDetailsLimited';


export interface LockActivityModel { 
    LockDetails?: LockDetailsModel;
    DateTimeUtc?: Date;
    UserId?: string;
    UserAlias?: string;
    User?: UserDetailsLimited;
    EventType?: LockActivityModel.EventTypeEnum;
    EventAction?: LockActivityModel.EventActionEnum;
    Value?: string;
    ResourceId?: string;
    AppId?: string;
    Platform?: LockActivityModel.PlatformEnum;
    AppVersion?: string;
    Offline?: boolean;
    TransactionId?: string;
}
export namespace LockActivityModel {
    export type EventTypeEnum = 'Locks' | 'LockGroups' | 'Permissions' | 'Bookings' | 'Teams' | 'Sites' | 'Assets' | 'Hubs';
    export const EventTypeEnum = {
        Locks: 'Locks' as EventTypeEnum,
        LockGroups: 'LockGroups' as EventTypeEnum,
        Permissions: 'Permissions' as EventTypeEnum,
        Bookings: 'Bookings' as EventTypeEnum,
        Teams: 'Teams' as EventTypeEnum,
        Sites: 'Sites' as EventTypeEnum,
        Assets: 'Assets' as EventTypeEnum,
        Hubs: 'Hubs' as EventTypeEnum
    };
    export type EventActionEnum = 'Locked' | 'Unlocked' | 'Created' | 'Modified' | 'Deleted' | 'Granted' | 'Revoked' | 'Battery' | 'Location' | 'Added' | 'Removed' | 'Ownership_Transferred' | 'Billing_Status_Changed' | 'Shackle_Removed' | 'Heartbeat' | 'Peg_Unlocked' | 'Peg_Removed' | 'Peg_Returned' | 'Door_Unlocked' | 'Unlock_All' | 'Request' | 'Lock_Status' | 'OfflineLocked' | 'OfflineUnlocked';
    export const EventActionEnum = {
        Locked: 'Locked' as EventActionEnum,
        Unlocked: 'Unlocked' as EventActionEnum,
        Created: 'Created' as EventActionEnum,
        Modified: 'Modified' as EventActionEnum,
        Deleted: 'Deleted' as EventActionEnum,
        Granted: 'Granted' as EventActionEnum,
        Revoked: 'Revoked' as EventActionEnum,
        Battery: 'Battery' as EventActionEnum,
        Location: 'Location' as EventActionEnum,
        Added: 'Added' as EventActionEnum,
        Removed: 'Removed' as EventActionEnum,
        OwnershipTransferred: 'Ownership_Transferred' as EventActionEnum,
        BillingStatusChanged: 'Billing_Status_Changed' as EventActionEnum,
        ShackleRemoved: 'Shackle_Removed' as EventActionEnum,
        Heartbeat: 'Heartbeat' as EventActionEnum,
        PegUnlocked: 'Peg_Unlocked' as EventActionEnum,
        PegRemoved: 'Peg_Removed' as EventActionEnum,
        PegReturned: 'Peg_Returned' as EventActionEnum,
        DoorUnlocked: 'Door_Unlocked' as EventActionEnum,
        UnlockAll: 'Unlock_All' as EventActionEnum,
        Request: 'Request' as EventActionEnum,
        LockStatus: 'Lock_Status' as EventActionEnum,
        OfflineLocked: 'OfflineLocked' as EventActionEnum,
        OfflineUnlocked: 'OfflineUnlocked' as EventActionEnum
    };
    export type PlatformEnum = 'Unknown' | 'Android' | 'Web' | 'iOS';
    export const PlatformEnum = {
        Unknown: 'Unknown' as PlatformEnum,
        Android: 'Android' as PlatformEnum,
        Web: 'Web' as PlatformEnum,
        IOS: 'iOS' as PlatformEnum
    };
}
