import { Injectable } from '@angular/core';
import { AvailableLockTypeModel } from '../models/AvailableLockTypeModel';
import { LockvueBaseConfigService } from './lockvue-base-config.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LockvueConfigProviderService extends LockvueBaseConfigService {
  constructor() {
    super();
  }

  /**
   * Name of the theme to be used for the front-end dashboard
   */
  getTheme(dark?: boolean): string {
    if (dark) {
      return 'lockvue_dark';
    }
    return 'lockvue';
  }

  /**
   * Gets the friendly name of the client such as 'LockVue' or
   * the name of the white-label partner for whom this front-end
   * is deployed.
   */
  getClientName(): string {
    return 'LockVue';
  }

  /**
   * Whether third-party integrations (such as YesBookIt) are
   * enabled (i.e. if those settings should be displayed at all)
   * or not.
   */
  integrationsAllowed(): boolean {
    return true;
  }

  /**
   * Returns the default center of the map shown on the dashboard homepage.
   * The returned object contains the following attributes:
   * { latitude, longitude, zoom }
   */
  getDashboardMapCenter(): any {
    return {
      latitude: -30,
      longitude: 150,
      zoom: 3,
    };
  }
  /**
   * Returns the list of lock manufacturers available for this deployment.
   * The list is returned in the priority order suitable for this deployment.
   */
  getLockManufacturers(): string[] {
    return [
      'Noke',
      'Smart Armor',
      'AirBolt',
      'Astute Access',
      'dormakaba',
      'BQT',
      'QR2id',
      'Allied Locks',
      'Indara',
    ];
  }

  /**
   * Returns the list of lock types (manufacturers) and their subtypes
   * in the order suitable for this deployment.
   */
  getLockTypes(): AvailableLockTypeModel[] {
    const qaSpecificLockTypes: AvailableLockTypeModel[] = [
      {
        manufacturer: 'Smart Armor',
        subtypes: ['Cube'],
      },

      {
        manufacturer: 'BQT',
        subtypes: ['Bolt Lock'],
      },
    ];

    const commonLockTypes: AvailableLockTypeModel[] = [
      {
        manufacturer: 'Astute Access',
        subtypes: [
          'Module',
          'Controller',
          'HD Padlock',
          'Mortice Strike',
          'Rim Strike',
          'Cabinet',
          'Locker',
          'KeyRack',
          'Cable Lock',
          'Safe Lock',
          'Parking Barrier',
        ],
      },
      {
        manufacturer: 'Noke',
        subtypes: ['Padlock', 'HD Padlock'],
      },
      {
        manufacturer: 'AirBolt',
        subtypes: ['Cable Lock'],
      },
      {
        manufacturer: 'dormakaba',
        subtypes: ['HD Padlock', 'Module', 'Bolt Lock'],
      },
      {
        manufacturer: 'QR2id',
        subtypes: ['HD Padlock', 'Cable Lock', 'Module', 'Mortice Strike', 'Rim Strike'],
      },
      {
        manufacturer: 'Allied Locks',
        subtypes: ['Dominator'],
      },
      {
        manufacturer: 'Indara',
        subtypes: ['HD Padlock'],
      },
    ];
    if (environment.production) {
      return commonLockTypes;
    } else {
      return [...commonLockTypes, ...qaSpecificLockTypes];
    }
  }

  /**
   * Returns the client url to be used in the PDF reports
   */

  getClientURL(): string {
    return 'go.lockvue.com';
  }

  /**
   * Returns Image and coordinates for PDF Reports
   */
  getLogoAndCoordinates(): any {
    const img = new Image();
    img.src = '../../assets/images/lockvue-logo-color.png';
    return {
      image: img,
      x: undefined,
      y: 10,
      width: 33,
      height: 8,
    };
  }
}
