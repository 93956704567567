import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  KeyRequestModel,
  LockDetailsWithOwnerModel,
  LocksPoolAdminService,
  ManualUnlockCodeModel,
} from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-admin-more-info',
  templateUrl: './admin-more-info.component.html',
  styleUrls: ['./admin-more-info.component.scss'],
})
export class AdminMoreInfoComponent implements OnDestroy {
  @Input() value: LockDetailsWithOwnerModel;

  private destroy$ = new Subject();

  codes: ManualUnlockCodeModel[];

  constructor(private locksPoolAdminService: LocksPoolAdminService) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormattedDate(date): string {
    if (!date) return 'Unkown';
    return formatDate(date, 'yyyy-MM-dd HH:mm', 'en');
  }

  hover() {
    const data: KeyRequestModel = {
      AppId: null,
      MAC: this.value.HWAddress,
      SerialNumber: null,
    };
    this.locksPoolAdminService
      .locksPoolAdminGetManualUnlockCode(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(code => {
        this.codes = code.filter(v => v.ManualUnlockCode);
      });
  }
}
