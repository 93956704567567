import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import { LockDetailsUIModel } from '../../models/LockDetailsWithPermissionModel';
import { NbWindowService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { TableSettingsProviderService } from '../../services/table-settings-provider.service';
import { of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { relativeTimeRounding } from 'moment';

@Component({
  selector: 'lockvue-locks-table',
  templateUrl: './locks-table.component.html',
  styleUrls: ['./locks-table.component.scss'],
})
export class LocksTableComponent implements OnInit, OnDestroy {
  tableSettings: any;
  private _locks: LockDetailsUIModel[];
  lockSource: LocalDataSource;
  tableEnabled = true;

  // Currently selected locks (using checkboxes)
  selectedLocks: LockDetailsUIModel[];

  // Row currently in focus (e.g. first row when a filter is applied, or the row user clicks)
  lockOnFocus: LockDetailsUIModel;

  _filters: any;

  destory$ = new Subject();
  lockStatusView = false;

  private lockStatusEnabledLockTypes = ['HD Padlock'];

  // stream to return formatted (comma separated) string from array.
  lockStatusEnabledLockTypes$ = of(this.lockStatusEnabledLockTypes).pipe(
    map(v => {
      if (v.length === 1) {
        return `${v[0]}.`;
      } else {
        return `${v.slice(0, -1).join(', ')}, ${v.slice(-1)[0]}.`;
      }
    }),
  );

  @Input() set locks(value: LockDetailsUIModel[]) {
    this._locks = value;
    this.lockSource = new LocalDataSource(value);
    this.setFilter();
    // subscribe does not work if put under onInit
    this.lockSource.onChanged().subscribe(event => {
      if (event.action === 'filter' || event.action === 'sort') {
        this.lockSource.getFilteredAndSorted().then(data => {
          this.tableFiltered.emit(data);
        });
      }
    });
    this.initTableSettings(); // refresh table settings
  }

  get locks(): LockDetailsUIModel[] {
    return this._locks;
  }

  @Input() set filters(value: any) {
    this._filters = value;
    this.setFilter();
  }

  get filters() {
    return this._filters;
  }

  @Output() selectedLocksChange: EventEmitter<LockDetailsUIModel[]>;

  @Output() lockUpdated: EventEmitter<LockDetailsUIModel>;

  @Output() tableFiltered: EventEmitter<{ lockStatus: boolean; data: LockDetailsUIModel[] }>;

  @ViewChild('editLockTemplate', { static: false }) editLockTemplate: TemplateRef<any>;

  constructor(
    private windowService: NbWindowService,
    private tableSettingsProviderService: TableSettingsProviderService,
  ) {
    this.selectedLocksChange = new EventEmitter();
    this.lockUpdated = new EventEmitter();
    this.tableFiltered = new EventEmitter();
  }

  ngOnInit() {
    this.initTableSettings();
  }

  ngOnDestroy() {
    this.destory$.next();
    this.destory$.complete();
  }

  onRowSelect(event: any) {
    this.lockOnFocus = event.data;
  }

  onUserRowSelect(event: any) {
    const selectedRows = event.selected;
    this.selectedLocks = selectedRows;
    this.selectedLocksChange.emit(selectedRows);
  }

  private initTableSettings() {
    this.tableEnabled = false;
    setTimeout(() => {
      this.tableEnabled = true;
    });
    const newSettings = this.tableSettingsProviderService.getLocksPageTableSettings(this._locks);
    this.tableSettings = Object.assign({}, newSettings);
  }

  onEditRequest(event): void {
    const lockDetails: LockDetailsUIModel = event.data;
    this.windowService.open(this.editLockTemplate, { title: 'Edit Lock', context: lockDetails });
  }

  onLockModified(updatedLockDetails: LockDetailsUIModel): void {
    // Propagate event
    this.lockUpdated.emit(updatedLockDetails);
  }

  setFilter() {
    if (this._filters) {
      this.lockSource.setFilter([{ field: 'UID', search: this._filters.SerialNumber }]);
    } else {
      this.lockSource.reset();
    }
  }

  lockStatusPressed($event) {
    if ($event) {
      this.lockStatusView = $event;
      const newSettings = this.tableSettingsProviderService.getLockStatusTableSettings(this._locks);
      const lockStatusEnabledLocks = this._locks.filter(
        l => l.LockStatus && l.LockStatus !== 'Locked',
      );
      this.lockSource.load(lockStatusEnabledLocks);
      this.tableFiltered.emit({ lockStatus: this.lockStatusView, data: lockStatusEnabledLocks });
      // temporary hack because of a bug where some table settings can't be changed dynamically and requires recreation of component
      this.tableEnabled = false;
      setTimeout(() => {
        this.tableSettings = Object.assign({}, newSettings);
        this.tableEnabled = true;
      });
    }
  }

  defaultPressed($event) {
    if ($event) {
      this.lockStatusView = !$event;
      this.tableFiltered.emit({ lockStatus: this.lockStatusView, data: this._locks });
      this.lockSource.load(this._locks);
      this.initTableSettings();
    }
  }
}
