<div class="container">
  <mat-form-field>
    <mat-chip-list #membersList aria-label="Manage team members (changes are saved immediately)">
      <mat-chip *ngFor="let member of cell.newValue" (removed)="remove(member)">
        {{ member.UserId }}
        &nbsp;<a><i matChipRemove class="ion-ios-close"></i></a>
      </mat-chip>
      <input
        placeholder="Manage team members (changes are saved immediately)"
        [matChipInputFor]="membersList"
        [matChipInputSeparatorKeyCodes]="SeparatorKeyCodes"
        (matChipInputTokenEnd)="add($event)" />
    </mat-chip-list>
  </mat-form-field>
</div>
