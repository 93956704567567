import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <footer>
      Powered by
      <div class="lockview-footer-logo"></div>
    </footer>
  `,
})
export class FooterComponent {}
