import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NbCalendarRange, NbDatepickerDirective } from '@nebular/theme';
import * as moment from 'moment';
import { NotificationMessagesService } from '../../services/notification-messages.service';

@Component({
  selector: 'lockvue-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
})
export class DateRangeSelectorComponent implements OnInit {
  _range: NbCalendarRange<Date>;
  differenceAllowed: number;
  get range(): NbCalendarRange<Date> {
    return this._range;
  }

  @Input()
  set range(value: NbCalendarRange<Date>) {
    this.updateRange(value);
  }

  @Input() isAdmin: boolean = false;

  @Output() rangeChange: EventEmitter<any>;

  @ViewChild(NbDatepickerDirective, { static: true }) dateRangePicker: NbDatepickerDirective<any>;

  constructor(private notificationService: NotificationMessagesService) {
    this.rangeChange = new EventEmitter();
  }

  ngOnInit() {
    this.differenceAllowed = this.isAdmin ? 3 : 12;
    // Displaying the selected range in the 'input' component
    // this.dateRangePicker.writeValue(this.range);
  }

  updateRange(value: NbCalendarRange<Date>) {
    this._range = value;
    this.dateRangePicker.writeValue(value);
  }

  // Helper to update range and sent event notification
  updateRangeAndNotify(start: Date, end: Date) {
    const value = {
      start: start,
      end: end,
    } as NbCalendarRange<Date>;
    this.updateRange(value);
    this.rangeChanged(value);
  }

  rangeChanged($event) {
    if ($event.start && $event.end) {
      if (moment($event.start).diff($event.end, 'months', false) < -this.differenceAllowed) {
        this.notificationService.showError(
          'Max Range!',
          `The difference between the date should be maximum ${this.differenceAllowed} months.`,
        );
      } else {
        this.rangeChange.emit($event);
      }
    }
  }

  // Handlers for different preset range selectors
  updateRange_today() {
    this.updateRangeAndNotify(moment().add(-1, 'days').utc().toDate(), moment().utc().toDate());
  }

  updateRange_week() {
    this.updateRangeAndNotify(moment().add(-1, 'weeks').utc().toDate(), moment().utc().toDate());
  }

  updateRange_month() {
    this.updateRangeAndNotify(moment().add(-1, 'months').utc().toDate(), moment().utc().toDate());
  }

  updateRange_3months() {
    this.updateRangeAndNotify(moment().add(-3, 'months').utc().toDate(), moment().utc().toDate());
  }

  updateRange_6Months() {
    this.updateRangeAndNotify(moment().add(-6, 'months').utc().toDate(), moment().utc().toDate());
  }

  updateRange_12Months() {
    this.updateRangeAndNotify(moment().add(-12, 'months').utc().toDate(), moment().utc().toDate());
  }

  dateRangeChanged($event) {
    this.updateRangeAndNotify(
      moment().add(-$event, 'days').utc().toDate(),
      moment().utc().toDate(),
    );
  }
}
