/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Contains information required when sharing a lock with YesBookIt
 */
export interface YBILockShareModel { 
    /**
     * Unique lock identifier (GUID)
     */
    LockId?: string;
    /**
     * Time in UTC when this permission is activated
     */
    StartTimeUTC?: Date;
    /**
     * Time in UTC when this permission is deactivated
     */
    EndTimeUTC?: Date;
}
