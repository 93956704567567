/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LockInHubDetailsModel } from './lockInHubDetailsModel';


export interface HubDetailsModel { 
    HubId?: string;
    Name?: string;
    Description?: string;
    HubType?: string;
    TotalRows?: number;
    TotalColumns?: number;
    LastHeartbeatUtc?: Date;
    UID?: string;
    HWAddress?: string;
    OfflineEnabled?: boolean;
    NotBilled?: boolean;
    Locks?: Array<LockInHubDetailsModel>;
}
