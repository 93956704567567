import { Component, Input, OnInit } from '@angular/core';
import { LockActivityModel, LockDetailsModel } from '../../lockvue-ng-sdk';
import { LockIconHelperService } from '../../services/lock-icon-helper.service';
import { SubjectService } from '../../services/subject.service';

@Component({
  selector: 'lockvue-activity-aware-lock-info-short',
  templateUrl: './activity-aware-lock-info-short.component.html',
  styleUrls: ['./activity-aware-lock-info-short.component.scss'],
})
export class ActivityAwareLockInfoShortComponent {
  iconColor: string = 'black';
  showTransactionIcon = false;
  private hasTransactionId = false;

  private transactionEnabledEvents = [LockActivityModel.EventActionEnum.Request];

  constructor(
    private lockIconHelperServcice: LockIconHelperService,
    private subjectService: SubjectService,
  ) {}

  @Input() value: LockDetailsModel;

  @Input() set rowData(value: any) {
    this.iconColor = this.lockIconHelperServcice.getIconColor(value);
    this.hasTransactionId = value.TransactionId ? true : false;
    this.showTransactionIcon =
      this.hasTransactionId && this.transactionEnabledEvents.includes(value.EventAction);
  }

  emitClickOnName() {
    this.subjectService.emitWithMessage({ for: 'adminTransactioalTelemetry' });
  }
}
