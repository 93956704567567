/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PermissionDetailsV2 } from '../model/permissionDetailsV2';
import { PermissionRequestModel } from '../model/permissionRequestModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

    protected basePath = 'https://astute-api-qa.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets the details of the permission identified by the given valid permission-id
     * 
     * @param Id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionsDetails(Id: string, observe?: 'body', reportProgress?: boolean): Observable<PermissionDetailsV2>;
    public permissionsDetails(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionDetailsV2>>;
    public permissionsDetails(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionDetailsV2>>;
    public permissionsDetails(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling permissionsDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PermissionDetailsV2>(`${this.basePath}/api/v2/Permissions/${encodeURIComponent(String(Id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all current and upcoming lock permissions associated with a lock group.
     * 
     * @param Id Valid group-id of an own lock group.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionsGroupActivePermissions(Id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionDetailsV2>>;
    public permissionsGroupActivePermissions(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionDetailsV2>>>;
    public permissionsGroupActivePermissions(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionDetailsV2>>>;
    public permissionsGroupActivePermissions(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling permissionsGroupActivePermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PermissionDetailsV2>>(`${this.basePath}/api/v2/Permissions/Groups/${encodeURIComponent(String(Id))}/Active`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all upcoming permissions for a specific lock owned / administered by the current user
     * 
     * @param Id A valid lock-id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionsLockSpecificUpcoming(Id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionDetailsV2>>;
    public permissionsLockSpecificUpcoming(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionDetailsV2>>>;
    public permissionsLockSpecificUpcoming(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionDetailsV2>>>;
    public permissionsLockSpecificUpcoming(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling permissionsLockSpecificUpcoming.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PermissionDetailsV2>>(`${this.basePath}/api/v2/Permissions/Locks/${encodeURIComponent(String(Id))}/Upcoming`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the permission entry identified by the given unique permission id.    Note that revoking a permission may not necessarily block a user from accessing a resource if there  are other valid permissions granted to that user for the same resource.
     * 
     * @param Id Valid Permission Id of an owned / administered lock
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionsRevoke(Id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public permissionsRevoke(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public permissionsRevoke(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public permissionsRevoke(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling permissionsRevoke.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/v2/Permissions/${encodeURIComponent(String(Id))}/Revoke`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all current and upcoming lock permissions associated with a team.
     * 
     * @param Id Valid team-id of an own team.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionsTeamActivePermissions(Id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionDetailsV2>>;
    public permissionsTeamActivePermissions(Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionDetailsV2>>>;
    public permissionsTeamActivePermissions(Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionDetailsV2>>>;
    public permissionsTeamActivePermissions(Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Id === null || Id === undefined) {
            throw new Error('Required parameter Id was null or undefined when calling permissionsTeamActivePermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PermissionDetailsV2>>(`${this.basePath}/api/v2/Permissions/Teams/${encodeURIComponent(String(Id))}/Active`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all upcoming permissions for locks owned / administered by the current user with filter and pagination options
     * 
     * @param RequestModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionsUpcoming(RequestModel: PermissionRequestModel, observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionDetailsV2>>;
    public permissionsUpcoming(RequestModel: PermissionRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionDetailsV2>>>;
    public permissionsUpcoming(RequestModel: PermissionRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionDetailsV2>>>;
    public permissionsUpcoming(RequestModel: PermissionRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (RequestModel === null || RequestModel === undefined) {
            throw new Error('Required parameter RequestModel was null or undefined when calling permissionsUpcoming.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<PermissionDetailsV2>>(`${this.basePath}/api/v2/Permissions/Upcoming`,
            RequestModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
