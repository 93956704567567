import { Component, OnInit } from '@angular/core';
import { NbTokenService } from '@nebular/auth';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LocalStorageKeys } from '../../../services/constants/LocalStorageKeys';

@Component({
  selector: 'lockvue-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private tokenService: NbTokenService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.tokenService.clear().subscribe();
    this.localStorageService.removeItem(LocalStorageKeys.Alias);
    this.router.navigate(['/auth/login']);
  }
}
