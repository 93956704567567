/**
 * LockVue API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LockStatusUpdateModel { 
    LockId?: string;
    AppId?: string;
    LockStatus?: LockStatusUpdateModel.LockStatusEnum;
    RecordedOnUtc?: Date;
    BatteryPercentage?: number;
    Recorder?: string;
}
export namespace LockStatusUpdateModel {
    export type LockStatusEnum = 'Unknown' | 'Unlocked' | 'Locked' | 'Shackle_Removed' | 'DoorShut' | 'DoorOpen';
    export const LockStatusEnum = {
        Unknown: 'Unknown' as LockStatusEnum,
        Unlocked: 'Unlocked' as LockStatusEnum,
        Locked: 'Locked' as LockStatusEnum,
        ShackleRemoved: 'Shackle_Removed' as LockStatusEnum,
        DoorShut: 'DoorShut' as LockStatusEnum,
        DoorOpen: 'DoorOpen' as LockStatusEnum
    };
}
