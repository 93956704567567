import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessagesService {
  constructor(private toastrService: NbToastrService) {}

  showSuccess(title: string, message: string) {
    this.toastrService.success(message, title);
  }

  showError(title: string, message: string, error?: any) {
    if (error) {
      // Modify message to include error details
    }
    this.toastrService.danger(message, title);
  }

  showWarning(title: string, message: string) {
    this.toastrService.warning(message, title);
  }
}
